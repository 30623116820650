import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import Result from '@microbadevs/report'
import i18n from '@microbadevs/report/src/language-pack'
import router from '@/router'
import * as Sentry from '@sentry/vue'
import VueJWT from 'vuejs-jwt'
import VueI18n from 'vue-i18n'
import { BootstrapVue } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'

const APP_CORE_API = process.env.VUE_APP_CORE_API
const SENTRY_AUTH_TOKEN = process.env.VUE_APP_SENTRY_AUTH_TOKEN

if (SENTRY_AUTH_TOKEN) {
  Sentry.init({
    Vue,
    dsn: 'https://8902a081085c6aadbeb5d62a8eebc144@o287399.ingest.sentry.io/4506256105537536',
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router)
      }),
      new Sentry.Replay()
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      'localhost',
      new RegExp(`^${APP_CORE_API}`)
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  })
}

Vue.config.productionTip = false
Vue.use(VueI18n)
Vue.use(VueRouter)
Vue.use(Result)
Vue.use(VueJWT)
Vue.use(BootstrapVue)
i18n.locale = 'es'
localStorage.setItem('locale', localStorage.getItem('locale') || i18n.locale)
new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
