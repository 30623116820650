<template>
    <maintenance buttonText="Retry" :error="error" :date="date" :message="message" :status="status" :onMount="poll" @leaveButtonClick="retry" />
  </template>
  <script>
  import { Maintenance } from '@microbadevs/library'

  const delayTime = 300000
  const buttonSuccessTime = 3000

  export default {
    name: 'MaintenanceContainer',
    components: { Maintenance },
    props: {
      status: {
        type: String
      },
      message: {
        type: String
      },
      date: {
        type: String
      }
    },
    data () {
      return {
        error: false
      }
    },
    methods: {
      async poll () {
        const pollingIntervalReq = setInterval(async () => {
          const res = await fetch(`${process.env.VUE_APP_CORE_API}users`)
          if (res.status !== 503) {
            clearInterval(pollingIntervalReq)
            this.$router.go(-1)
          }
        }, delayTime)
      },
      async retry () {
        const res = await fetch(`${process.env.VUE_APP_CORE_API}users`)
        if (res.status === 503) {
          this.error = true
          setTimeout(() => { this.error = false }, buttonSuccessTime)
        } else {
          this.error = false
          this.$router.go(-1)
        }
      }
    }
  }
  </script>
