<template>
  <div>
    <ReportContainer
      :token="token"
      :shopping-only="true"
    />
  </div>
</template>

<script>
  import ReportContainer from '@/components/reportContainer'
  export default {
    name: 'shoppingContainer',
    components: {
      ReportContainer
    },
    props: {
      token: {
        type: String,
        default: null,
        required: true
      }
    }
  }
</script>
