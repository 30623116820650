<template>
  <div class="report-container">
    <div class="container">
      <div class="d-flex my-5 justify-content-between">
        <img alt="synlab-logo" src="../assets/images/logos/synlab-logo.svg" />
        <microba-results
          v-if="dataIsReady && !shoppingOnly"
          :primarySample="primarySample"
          :languagesToggleOnly="true"
          :isExternalReference="false"
          :hideHeader="true"
          :hideName="true"
          @serviceUnavailable="handleServiceUnavailable"
        />
      </div>
      <div class="d-flex mb-5 justify-content-between">
        <img alt="mybiome-logo" src="../assets/images/logos/mybiome-logo.svg" />
        <img
          alt="mybiome-logo"
          class="img-fluid h-75"
          src="../assets/images/logos/microba-logo.jpg"
        />
      </div>
    </div>
    <microba-results
      v-if="dataIsReady"
      :primarySample="primarySample"
      :hideHeader="true"
      :hideName="true"
      :compare="false"
      :isExternalReference="false"
      :exportURL="exportUrl"
      :shoppingOnly="shoppingOnly"
      @serviceUnavailable="handleServiceUnavailable"
    />
    <div v-else-if="tokenExpired">
      <h2>Link Expired</h2>
      <p class="text-danger">
        For security reasons, the link has expired (30 days validity).
      </p>
    </div>
    <div class="versionInfo">{{ versionInfo.tag }}-{{ versionInfo.hash }}</div>
  </div>
</template>

<script>
  import tokenDecoder from '@microbadevs/token-decoder'
  export default {
    name: 'ReportContainer',
    props: {
      token: {
        type: String,
        default: null,
        required: true
      },
      shoppingOnly: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        exportUrl: process.env.VUE_APP_SHOPPING_LIST,
        decodedToken: null
      }
    },
    computed: {
      dataIsReady () {
        return !this.tokenExpired && this.primarySample
      },
      tokenExpired () {
        if (!this.token) {
          return false
        }

        // Date.now() returns milli seconds but token contains expiry in seconds.
        return this.decodedToken && this.decodedToken.exp
          ? Date.now() >= this.decodedToken.exp * 1000
          : false
      },
      primarySample () {
        if (!this.decodedToken || !this.decodedToken.authorities) {
          return null
        }
        // Bad Bad not happy with this implementation ticket added for discussion with BE team (MA-599)
        // Once above ticket is completed this should be revisited for proper implementation.
        const sampleIdentifier = this.decodedToken.authorities.find(
          (authority) => {
            return authority.match(/^[a-z]{3}[0-9]{4}$/i)
          }
        )

        return { identifier: sampleIdentifier }
      },
      versionInfo () {
        return {
          tag: process.env.VUE_APP_TAG,
          hash: process.env.VUE_APP_HASH
        }
      }
    },
    methods: {
      handleServiceUnavailable (args) {
        this.$router.push({ name: 'maintenance', params: { ...args } })
      }
    },
    mounted () {
      localStorage.setItem('report_path', process.env.VUE_APP_RESULT_API)
      localStorage.setItem('api_path', process.env.VUE_APP_CORE_API)
      localStorage.setItem('storage_api_path', process.env.VUE_APP_STORAGE_API)
      localStorage.setItem('public_storage', process.env.VUE_APP_PUBLIC_URL)
      localStorage.setItem('access_token', this.token)
      tokenDecoder.decode(this.token).then((decodedToken) => {
        this.decodedToken = decodedToken.payload
      })
    }
  }
</script>

<style lang="scss">
  @import '../styles/theme_synlab';

  .btn-language-toggle {
    height: 50px;
    width: 140px;
    border: 2px $theme-dark solid;
    border-radius: 25px;
  }

  .versionInfo {
    text-align: center;
    padding-bottom: 10px;
  }
</style>
