import VueRouter from 'vue-router'
import reportContainer from '@/components/reportContainer'
import shoppingContainer from '@/components/shoppingContainer'
import notFound from '@/components/notFound'
import maintenance from '@/components/maintenance'

export default new VueRouter({
  mode: 'history',
  routes: [
    {
      name: 'maintenance',
      path: '/maintenance',
      component: maintenance,
      props: true
    },
    {
      name: 'insight-report',
      path: '/:countryCode/:token',
      component: reportContainer,
      props: true
    }, {
      path: '/:countryCode/shopping/:token',
      component: shoppingContainer,
      props: true
    }, {
      path: '*',
      component: notFound,
      props: true
    }
  ]
})
